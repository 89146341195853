@import "../../../Styles/resources";

.new-council {
  font-family: "Roboto";
  background-color: #f5f5f5;
  scroll-behavior: smooth;
  .hero_image2 {
    min-height: 50vh;
    background: url("../../../images/new-images/Peoplewithuhari.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }

    .cover {
      width: 100%;
      max-width: 800px;
      h1 {
        color: #fff;
        font-size: 50px;
        font-weight: 700;
        padding-bottom: 15px;
        @include mobile-3 {
          font-size: 30px;
        }
      }
      p {
        color: #fff;
        font-size: 30px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
    }
  }
  .hero_image {
    min-height: 50vh;
    background: url("../../../images/new-images/Peoplewithuhari.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
    .cover {
      width: 100%;
      max-width: 800px;
      h2 {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 10px;
        @include mobile-3 {
          font-size: 14px;
        }
        text-transform: capitalize;
      }
      h1 {
        color: #fff;
        font-size: 50px;
        font-weight: 700;
        padding-bottom: 15px;
        @include mobile-3 {
          font-size: 30px;
        }
      }
      p {
        color: #fff;
        font-size: 30px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
    }
  }

  .members {
    padding: 0px 80px;
    @include mobile-4 {
      padding: 0px 30px;
    }

    .options {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 60px 0px 20px 0px;
      gap: 30px;

      @include mobile-3 {
        flex-direction: column;
      }

      .option_item {
        font-weight: 700;
        color: #717861;
      }
      .option_item_active {
        font-weight: 700;
        color: #2b3513;
        font-size: 30px;
      }
    }

    article {
      margin: 80px 0px;

      .article-header {
        color: #2b3513;
        font-weight: 400;
        font-size: 35px;
        margin: 10px 0px;
        text-align: center;
      }
      .article-desc {
        margin: 10px 0px;
        font-size: 16px;
        color: #717861;
        text-align: center;
      }

      .member_items {
        display: grid;
        grid-template-columns: auto auto;
        // justify-items: center;
        align-items: center;
        gap: 40px;
        margin: 20px 0px;
        @include mobile-900px {
          grid-template-columns: 100%;
        }

        .member_item_centered {
          display: flex;
          grid-column: 1 / 3;
          @include mobile-900px {
            grid-column: 1;
            width: 100%;
          }
          @include mobile-4 {
            flex-direction: column;
            align-items: center;
          }

          .left {
            width: 200px;
            height: 200px;
            border-radius: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 10px;
            }
          }
          .right {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .header {
              font-size: 25px;
              font-weight: 700;
              margin: 10px 0px;
              color: #555d42;
            }
            .detail {
              font-size: 14px;
              font-weight: 400;
              margin: 5px 0px;
              color: #000;
            }
          }
        }

        .member_item {
          display: flex;
          align-items: center;
          @include mobile-900px {
            grid-column: 1;
            width: 100%;
          }
          @include mobile-2 {
            text-align: center;
            flex-direction: column;
            align-items: center;
          }

          // overflow: auto;

          .left {
            width: 200px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 10px;
            }
          }
          .right {
            margin-left: 20px;
            display: flex;
            flex-direction: column;

            @include mobile-2 {
              align-items: center;
            }
            flex: 1;
            .header {
              font-size: 16px;
              font-weight: 700;
              margin: 5px 0px;
              color: #555d42;
              word-break: break-word;
            }
            .detail {
              font-size: 14px;
              font-weight: 400;
              margin: 5px 0px;
              color: #000;
            }
          }
        }
      }
    }
  }
}
