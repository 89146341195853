@import "../../Styles/resources";

.code-conduct {
  .topBg {
    min-height: 50vh;
    min-height: 50vh;
    background: url("../../images/new-images/Peoplewithuhari.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
  }

  .code-conduct-content {
    position: relative;
    padding: 20px 30px;
    background-color: #eeeeee;

    @include mobile-4 {
      padding: 20px 10px;
    }

    .overlapModal {
      position: relative;
      padding: 30px;
      border-radius: 10px;
      margin: 0px 50px 0px 50px;
      text-align: center;
      background-color: #fff;
      top: -50px;

      @include mobile-3 {
        margin: 0px;
      }

      h1 {
        font-weight: 400;
        font-size: 50px;
        color: #2b3513;
        margin: 20px 0px;
      }

      p {
        line-height: 25px;
        color: #2b3513;
        margin: 10px 0px;
        font-size: 20px;
      }
    }

    .endeavour-items {
      margin: 40px 0px;
      display: grid;
      gap: 30px;
      grid-template-columns: auto auto;
      @include mobile-2 {
        grid-template-columns: auto;
      }

      .endeavour-item {
        display: flex;
        gap: 20px;
        h1 {
          font-size: 40px;
          color: #2b3513;
        }
        p {
          line-height: 25px;
        }
      }
    }
  }
  .achievements {
    background-color: #343a40;
    padding: 20px 60px;
    color: #fff;
    margin: 0px 0px 80px 0px;

    @include mobile-4 {
      padding: 20px 10px;
    }

    h1 {
      text-align: center;
      margin: 30px 0px;
      font-size: 40px;
    }
    p {
      font-size: 16px;
      text-align: center;
      margin: 10px 0px;
      padding: 0px 80px;

      @include mobile-2 {
        padding: 0px;
      }
    }

    .achievements-items {
      margin: 40px 0px;
      display: grid;
      gap: 40px;
      grid-template-columns: auto auto;
      @include mobile-2 {
        grid-template-columns: auto;
      }

      .achievements-item {
        display: flex;
        gap: 10px;
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        h5 {
          flex: 1;
          font-weight: 400;
          font-size: 18px;
        }
      }
    }
  }
}
