@import "../../Styles/resources";

.private-sector {
  .topBg {
    min-height: 50vh;
    min-height: 50vh;
    background: url("../../images/new-images/Peoplewithuhari.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
  }

  .private-sector-content {
    position: relative;
    padding: 20px 30px;
    background-color: #eeeeee;

    @include mobile-4 {
      padding: 20px 10px;
    }

    .overlapModal {
      position: relative;
      padding: 30px;
      border-radius: 10px;
      margin: 0px 50px 0px 50px;
      text-align: center;
      background-color: #fff;
      top: -50px;

      @include mobile-3 {
        margin: 0px;
      }

      h1 {
        font-weight: 400;
        margin: 20px 0px;
      }

      p {
        line-height: 25px;
        margin: 10px 0px;
      }
    }

    .core-values {
      margin: 20px 0px 60px 0px;
      h1 {
        color: #2b3513;
        margin: 20px 0px;
        text-align: center;
        font-size: 35px;
      }

      .core-values-items {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-evenly;
        gap: 40px;
        margin: 30px 0px;

        @include mobile-2 {
          display: grid;
          width: 100%;
          grid-template-columns: auto;
        }

        .core-values-item {
          width: calc((100% - 40px) / 2);
          display: flex;
          gap: 20px;
          padding: 10px;
          align-items: center;
          color: #fff;
          @include mobile-2 {
            width: 100%;
          }

          h3 {
            font-size: 35px;
          }

          .right {
            h5 {
              margin: 10px 0px;
            }
            p {
              margin: 10px 0px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  .achievements {
    background-color: #343a40;
    padding: 20px 30px;
    color: #fff;
    margin: 0px 0px 80px 0px;

    @include mobile-4 {
      padding: 20px 10px;
    }

    h1 {
      text-align: center;
      margin: 30px 0px;
      font-size: 40px;
    }
    p {
      font-size: 16px;
      text-align: center;
      margin: 10px 0px;
      padding: 0px 80px;

      @include mobile-2 {
        padding: 0px;
      }
    }

    .achievements-items {
      margin: 40px 0px;
      display: grid;
      gap: 40px;
      grid-template-columns: auto auto;
      @include mobile-2 {
        grid-template-columns: auto;
      }

      .achievements-item {
        display: flex;
        gap: 10px;
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        h5 {
          flex: 1;
          font-weight: 400;
          font-size: 18px;
        }
      }
    }
  }
}
