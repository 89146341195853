.new-img-banner {
  .new-banner-img-con {
    height: 50vh;
    overflow: hidden;

    .topBg {
      min-height: 50vh;
      min-height: 50vh;
      padding: 50px;
      // @include mobile-3 {
      //   padding: 50px 20px;
      // }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  section {
    padding: 10px 20px;
    border-top: 5px solid #2b3513;
    border-bottom: 5px solid #2b3513;
    color: #2b3513;
    p {
      font-size: 16px;
    }
    .header {
      font-size: 30px;
      margin: 20px 0px;
    }
  }
}
