@import "../../Styles/resources";

.publications-details {
  .hero_image {
    min-height: 50vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../images/new-images/InsightBgImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
    .cover {
      width: 100%;
      max-width: 800px;
      h5 {
        font-size: 18px;
        font-weight: 400;
        margin: 20px 0px;
        color: #fff;
      }
      h2 {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 10px;
        @include mobile-3 {
          font-size: 14px;
        }
        text-transform: capitalize;
      }
      h1 {
        color: #fff;
        font-size: 50px;
        font-weight: 400;
        padding-bottom: 15px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
      p {
        color: #fff;
        font-size: 30px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
    }
  }

  .news_main {
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    font-family: "Roboto";
    .cover {
      width: 100%;
      max-width: 1300px;
      display: flex;
      justify-content: space-between;
      padding: 100px 30px;
      flex-wrap: wrap;
      @include mobile-1 {
        padding: 100px 20px;
      }
      .left {
        width: 55%;
        height: 600px;
        background-color: #fff;
        padding: 30px;
        @include mobile-1 {
          width: 100%;
          padding: 30px 10px;
        }

        .pub-paragraph {
          margin: 20px 0px;
        }
        .readmore-link {
          color: blue;
          text-decoration: underline;
        }
        h2 {
          font-weight: 400;
          color: #4a4a4a;
          font-size: 25px;
          padding-bottom: 40px;
        }
        p {
          color: #4a4a4a;
          font-size: 18px;
          font-weight: 300;
          line-height: 30px;
        }
        .botom {
          margin-top: 100px;
          border-top: 2px solid #d7d6d6;
          padding-top: 20px;
          svg {
            color: #4a4a4a;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .right {
        width: 30%;
        @include mobile-1 {
          width: 100%;
          padding-top: 50px;
        }
        h2 {
          text-align: center;
          color: #4a4a4a;
          font-size: 30px;
          padding-bottom: 32px;
        }
        .wrap {
          .card {
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 20px;
            button {
              background: none;
              padding: 7px 20px;
              color: #2b3513;
              font-size: 10px;
              border: 1px solid #2b3513;
              border-radius: 40px;
              font-family: "Roboto";
              margin-bottom: 8px;
            }
            .flex {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 10px;
              h3 {
                color: #2b3513;
                font-size: 16px;
                width: 90%;
              }
              svg {
                font-size: 16px;
                cursor: pointer;
              }
            }
            p {
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #4e5665;
              font-size: 14px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
