@import "../../../Styles/resources";

.reports-preview {
  .preview-image {
    height: 50vh;
    background-color: #ddd;
    @include mobile-6 {
      height: 40vh;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .preview-text {
    padding: 20px 0px;
    .title-text {
      text-transform: capitalize;
      margin-bottom: 10px !important;
      padding-bottom: 0px !important;
    }
    .details-header {
      text-transform: capitalize;
    }
    .date-text {
      font-size: 14px !important;
    }
    p {
      color: #2b3513;
      margin: 10px 0px;
    }
    a {
      margin: 30px 0px;
    }
  }
}
